@import "../../../styles/_variables.scss";

.register-form {
  .form {
    margin-bottom: $margin-lg;
    
    .containerField {
      margin: 0 0 7px 0;
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .containerSubmit {

    .containerBtn {
      display: flex;
      align-items: center;
      > a {
        margin-top: 0;
        margin-left: $margin-sm;
      }
    }

    >a {
      display: block;
      position: relative;
      color: $text-color;
      margin-top: $margin-sm;
    }

    button {
      width: 100%;
    }

  }

}
