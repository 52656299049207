@import "../../../styles/_variables.scss";

.dropInputFile {
  width: 100%;
  height: 100%;
  .actions {
    display: flex;
    margin-top: $margin-sm;
  }

}

.dropInputFile .container {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;

  &.withFile label > div {
    p {
      color: $secondary-color;
      margin-right: auto;
    }
    .delete {
      position: relative;
      top: 1px;
      margin-left: $margin-xs;
    }
  }

  &:not(.withFile) label > div p {
    color: rgba($text-color-light, 0.7);
  }

  input {
    display: none;
  }

  label > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $light-grey;
    cursor: pointer;
    border-radius: $radius;
    
    p {
      color: $secondary-color;
      margin: 0;
      padding: $margin-xs;
    }

    &.dragActive {
      border-color: $black;
    }    
  }

  .dragFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

}




