@import "../../styles/_variables.scss";

.navbar {
  position: relative;
  display: flex;
  background-color: $white;
  width: 100%;
  justify-content: center;
  height: 64px;

  ul {
    margin: 0;
    padding: 0;
    li {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      height: 64px;
      padding: 0 $margin-lg;
      a {
        display: block;
        letter-spacing: 1%;
        &.active {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            height: 3px;
            background-color: $primary-color;
          }
        }
        
      }
    }
  }

  .profile {
    position: absolute;
    top: 50%;
    right: $margin-md;
    transform: translateY(-50%);
    button {
      color: $primary-color;
    }
  }

}