@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_page-auth.scss';

.register {
  @include page-auth();
  margin-top: $margin-lg;

  .center{
    text-align: center;
    margin-top: $margin-sm;
    a{
      color: $text-color-light;
      text-decoration: underline;
      svg{
        position: relative;
        top: 3px;
        margin-right: 3px;
      }
    }
  }

}