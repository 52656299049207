
@import './_variables.scss';

@mixin page-auth {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: $bg-color;
  padding: $margin-md $margin-sm;

  @media screen and (min-width: $md-screen) {
    align-items: center;
    padding: 0 $margin-sm;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .picture {
      padding: $margin-md;
      img {
        width: 100%;
        max-width: 500px;
      }
    }
    
    .containerForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      >h1 {
        @include h3();
        font-family: 'DMSans-Regular';
        text-align: center;
        margin-bottom: $margin-md;
        padding: 0 $margin-lg;
      }

      .form {
        width: 100%;
        max-width: 320px;
        background-color: $white;
        border-radius: 8px;
        border: solid 1px $border-grey;
        padding: $margin-lg;
        input {
          min-width: 250px;
        }
      }
  
    }

    @media screen and (min-width: $md-screen) {
      flex-direction: row;

      .containerForm {
        .form {
          max-width: 500px;
        }
      }

    }

    @media screen and (min-width: $lg-screen) {
      flex-direction: row;

    }
    
  }
}