@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.list-tags{
  width: 100%;
  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label{
      margin-right: 10px;
      min-width: 125px;
    }
  }

  &:not(.inline){
    label+ul{
      margin-top: $margin-sm;
    }
  }

  ul{
    padding: 0;
    margin: 0;
    li{
      display: inline-block;
      list-style: none;
      margin: 0 $margin-sm $margin-sm 0;
      button {
        font-family: "DMSans-Bold";
        border: solid 1px rgba(255, 255, 255, 0.32);
        color: $white;
        background-color: rgba(0, 0, 0, 0.09);
        border-radius: $radius;
        padding: 10px;
        min-width: 90px;
      }
      button.selected {
        background-color: $white;
        color: $text-color;
        border: solid 1px $white;
      }
    }
  }
}
