@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home {
  .content {
    @include content();

    h1, h2, li {
      color: $white;
    }

    h2 {
      margin: $margin-md 0 $margin-sm 0;
    }

    ul {
      margin: 0 $margin-sm $margin-md $margin-sm;
      li {
        margin-bottom: $margin-xs;
        .revoke {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }


    button {
      margin-right: $margin-sm;
    }
  }
}