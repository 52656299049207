@import './_variables.scss';
.toast {
  background-color: $success;
  box-shadow: 2px 2px 10px 0px rgba(131, 135, 157, 0.3);
}

.progressBar {
  background-color: $white;
  &:after {
    background-color: $primary-color;
  }
}